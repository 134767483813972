import React from 'react'
import { IconProps } from '../IconProps'

export function Search({
  color = '#676767',
  width = '12px',
  height = '12px'
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.52925 9.13807C7.2689 8.87772 7.2689 8.45561 7.52925 8.19526C7.7896 7.93491 8.21171 7.93491 8.47206 8.19526L11.1387 10.8619C11.3991 11.1223 11.3991 11.5444 11.1387 11.8047C10.8784 12.0651 10.4563 12.0651 10.1959 11.8047L7.52925 9.13807Z'
        fill={color}
      />
      <path
        d='M5.33366 8.66699C7.17461 8.66699 8.66699 7.17461 8.66699 5.33366C8.66699 3.49271 7.17461 2.00033 5.33366 2.00033C3.49271 2.00033 2.00033 3.49271 2.00033 5.33366C2.00033 7.17461 3.49271 8.66699 5.33366 8.66699ZM5.33366 10.0003C2.75633 10.0003 0.666992 7.91099 0.666992 5.33366C0.666992 2.75633 2.75633 0.666992 5.33366 0.666992C7.91099 0.666992 10.0003 2.75633 10.0003 5.33366C10.0003 7.91099 7.91099 10.0003 5.33366 10.0003Z'
        fill={color}
      />
    </svg>
  )
}
