import React from 'react'
import { IconProps } from '../IconProps'

export function LightBulb({
  color = '#80878D',
  width = '18px',
  height = '24px'
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.5138 2.05509C13.5174 1.23564 12.3507 0.648959 11.0987 0.337842C9.84678 0.0267249 8.54115 -0.000975897 7.27711 0.256761C5.59402 0.596735 4.04985 1.42901 2.84052 2.64798C1.63119 3.86696 0.811206 5.41769 0.484611 7.10343C0.246112 8.36738 0.289451 9.66838 0.611537 10.9137C0.933623 12.1589 1.52652 13.3178 2.34795 14.3076C3.11027 15.1678 3.54723 16.2679 3.58295 17.4168V20.6668C3.58295 21.5287 3.92536 22.3554 4.53485 22.9649C5.14434 23.5744 5.97099 23.9168 6.83295 23.9168H11.1663C12.0282 23.9168 12.8549 23.5744 13.4644 22.9649C14.0739 22.3554 14.4163 21.5287 14.4163 20.6668V17.6226C14.4526 16.3542 14.9186 15.1357 15.7379 14.1668C17.1735 12.3909 17.8502 10.1203 17.621 7.84836C17.3917 5.5764 16.2751 3.48671 14.5138 2.03343V2.05509ZM12.2496 20.6668C12.2496 20.9541 12.1355 21.2296 11.9323 21.4328C11.7291 21.636 11.4536 21.7501 11.1663 21.7501H6.83295C6.54563 21.7501 6.27008 21.636 6.06691 21.4328C5.86375 21.2296 5.74961 20.9541 5.74961 20.6668V19.5834H12.2496V20.6668ZM14.0588 12.8234C12.9695 14.1154 12.334 15.729 12.2496 17.4168H10.0829V14.1668C10.0829 13.8794 9.96881 13.6039 9.76564 13.4007C9.56248 13.1976 9.28693 13.0834 8.99961 13.0834C8.71229 13.0834 8.43674 13.1976 8.23358 13.4007C8.03042 13.6039 7.91628 13.8794 7.91628 14.1668V17.4168H5.74961C5.72103 15.7572 5.10714 14.1611 4.01628 12.9101C3.29656 12.0478 2.81258 11.0136 2.61153 9.90855C2.41049 8.80347 2.49923 7.66515 2.86912 6.60459C3.23901 5.54402 3.87742 4.5974 4.72209 3.85703C5.56677 3.11666 6.58888 2.60781 7.68878 2.38009C8.63404 2.18547 9.61079 2.20377 10.5481 2.43369C11.4854 2.6636 12.3598 3.09935 13.1077 3.70929C13.8556 4.31924 14.4583 5.08808 14.872 5.95999C15.2858 6.8319 15.5001 7.785 15.4996 8.7501C15.5076 10.2341 14.9981 11.6745 14.0588 12.8234V12.8234Z'
        fill={color}
      />
    </svg>
  )
}
