import React from 'react'
import { IconProps } from '../IconProps'

export function VideoCamera({
  color = '#80878D',
  width = '24px',
  height = '15px'
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.436 1.38C23.2536 1.27468 23.0466 1.21923 22.836 1.21923C22.6254 1.21923 22.4184 1.27468 22.236 1.38L18 3.468C17.9658 2.53628 17.5715 1.65417 16.9 1.00733C16.2286 0.360486 15.3323 -0.00062613 14.4 8.14988e-07H3.6C2.64522 8.14988e-07 1.72955 0.379286 1.05442 1.05442C0.379285 1.72955 0 2.64522 0 3.6V10.8C0 11.7548 0.379285 12.6705 1.05442 13.3456C1.72955 14.0207 2.64522 14.4 3.6 14.4H14.4C15.3323 14.4006 16.2286 14.0395 16.9 13.3927C17.5715 12.7458 17.9658 11.8637 18 10.932L22.272 13.068C22.4356 13.1519 22.6162 13.197 22.8 13.2C23.0247 13.2007 23.245 13.1384 23.436 13.02C23.6089 12.9119 23.7515 12.7615 23.8502 12.5831C23.9488 12.4046 24.0004 12.2039 24 12V2.4C24.0004 2.19607 23.9488 1.99539 23.8502 1.81693C23.7515 1.63846 23.6089 1.48809 23.436 1.38ZM15.6 10.8C15.6 11.1183 15.4736 11.4235 15.2485 11.6485C15.0235 11.8736 14.7183 12 14.4 12H3.6C3.28174 12 2.97652 11.8736 2.75147 11.6485C2.52643 11.4235 2.4 11.1183 2.4 10.8V3.6C2.4 3.28174 2.52643 2.97652 2.75147 2.75147C2.97652 2.52643 3.28174 2.4 3.6 2.4H14.4C14.7183 2.4 15.0235 2.52643 15.2485 2.75147C15.4736 2.97652 15.6 3.28174 15.6 3.6V10.8ZM21.6 10.056L18 8.256V6.144L21.6 4.344V10.056Z'
        fill={color}
      />
    </svg>
  )
}
